import { Link } from "gatsby"
import React, {useState} from "react"
import axios from 'axios'


const NavBar = (props) => {
  const url = 'https://o4vwxosrx2.execute-api.us-east-1.amazonaws.com/v1/email/send'
  const [contactStatus, setContactStatus] = useState('')
  const [showResponseModal, setResponseModal] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('') 


  const toggleResponseModal = (v) => {
    setResponseModal(!showResponseModal)
  }
  const handleNameChange = (v) => {
    setName(v.target.value)
  }
  const handleEmailChange = (v) => {
    setEmail(v.target.value)
  }
  const handleMessageChange = (v) => {
    setMessage(v.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      name: name,
      email: email,
      message: message
    };
    setContactStatus('Sending...')
    toggleResponseModal()
    try {
      let response = await axios.post(url, data, {timeout: 8000});
      // let resdata = response.data
      // if response.
      console.log(response)
      if(response.status === 200) {
        setContactStatus('Thanks for your submission. We will get back to you ASAP')
      } else {
        setContactStatus('Form submission failed. Sorry for the incovenience. We are working to resolve the issue')

      }
    } catch(err) {
      console.log(err)
      setContactStatus('Form submission failed. Sorry for the incovenience. We are working to resolve the issue')
    }
    
    setName('')
    setEmail('')
    setMessage('')
    
  }


  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">Sorensen Solutions</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-4 mb-2 mb-md-0">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle text-light" id="serviceDropdown" data-bs-toggle="dropdown" aria-expanded="false" to="#">Services</Link>
                <ul className="dropdown-menu" aria-labelledby="serviceDropdown">
                  <li><Link className="dropdown-item" to="/cloud/">Cloud Consulting</Link></li>
                  <li><Link className="dropdown-item" to="/web/">Web Development & Hosting</Link></li>
                  <li><Link className="dropdown-item" to="/automation/">Integration & Automation</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-light" to="/about/">About</Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <button className="nav-link btn btn-primary text-light" data-bs-toggle="modal"  data-bs-target="#contactUsModal">
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      
      {/* Contact form modal config */}
      <div
              className='modal fade text-dark'
              id="contactUsModal"
              tabIndex="-1"
              aria-labelledby="contactLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="contactLabel">
                      Contact Us
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"

                    ></button>
                  </div>
                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div className="modal-body">
                      We're ready to help you with all your technology needs. Fill
                      out your details and we'll get in touch as soon as possible
                      to discuss with you.
                      <div className="mt-4 mb-2 mx-6">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input type="text" 
                          value={name} 
                          onChange={handleNameChange} 
                          className="form-control" 
                          id="name" />
                      </div>
                      <div className="mb-2 mx-6">
                        <label htmlFor="email" className="form-label">
                          Email address
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={handleEmailChange} 
                          className="form-control"
                          id="email"
                          aria-describedby="contactEmail"
                        />
                      </div>
                      <div className="mb-2 mx-6">
                        <label htmlFor="message" className="form-label">
                          Brief description of your needs
                        </label>
                        <textarea
                          value={message}
                          onChange={handleMessageChange} 
                          className="form-control"
                          id="message"
                          rows="3"
                          maxLength="250"
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#contactUsModal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              className={`modal fade text-dark ${showResponseModal ? 'show' : ''}`}
              id="formSubmit"
              style={{
                display: `${showResponseModal ? 'block' : 'none'}`,
              }}
              tabIndex="-1"
              aria-labelledby="formSubmitLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="formSubmitLabel">
                      Form submitted
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={toggleResponseModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div id="contactStatus">{contactStatus}</div>
                  </div>
                </div>
              </div>
            </div>
    </header>
    
  );
}


export default NavBar