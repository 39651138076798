/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "../styles/site.css"
import NavBar from "./navbar"

const Layout = ({ children }) => {
  return (

    <>
      <NavBar />
        <main>{children}</main>
        
        <footer className="footer mt-auto py-3">
          <div className="container">
            <p>
              &copy; Sorensen Solutions, LLC {new Date().getFullYear()} &middot;
              Knoxville, TN &middot;{" "}
              <Link className="text-light" to="/privacy/">
                Privacy Policy
              </Link>
            </p>
          </div>
        </footer>
    </>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
